import usePodcastSeries from "../hooks/usePodcastSeries";
import { FeedItemCard } from "../schema";
import CultureCard from "./CultureCard";
import PodcastSeriesCard from "./PodcastSeriesCard";

export interface Props {
  cards: FeedItemCard[];
  podcast?: boolean;
}

export default function CardsGrid(props: Props) {
  const [series] = usePodcastSeries();

  return (
    <div
      className={`h-0 min-h-max grid xl:grid-cols-12 md:grid-cols-2 grid-cols-4 gap-4 lg:gap-8 w-full place-items-start`}
    >
      {props.cards.map(
        ({ post_summary, created_at, id, post_thumbnail, title, post_meta, title_slug, type }) => {
          return (
            <CultureCard
              hoverText={post_summary}
              date={created_at}
              key={id}
              title={title}
              imgSrc={post_thumbnail}
              imgAlt={title}
              author={post_meta.author}
              slug={title_slug}
              type={type}
            />
          );
        }
      )}
      {props.podcast &&
        series.map((series) => {
          return <PodcastSeriesCard key={series.id} series={series} />;
        })}
    </div>
  );
}
