import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import useCompanyProfile from "../hooks/useCompanyProfile";
import useTextTruncation from "../hooks/useTextTruncation";
import { RootState } from "../redux/configureStore";
import { closeModal } from "../redux/features/memberModalSlice";
import Loading from "./Loading";
import MarkDown from "./MarkDown";
import WebsiteLinkButton from "./WebsiteLinkButton";

export default function MemberPopup() {
  const dispatch = useDispatch();
  const { open, name } = useSelector((state: RootState) => state.memberModal);
  const close = () => {
    dispatch(closeModal());
  };
  const [memberData, isLoading] = useCompanyProfile(name, true);
  const truncatedText = useTextTruncation(memberData?.description_summary || "", 200);

  return (
    <Modal
      open={open}
      onClose={close}
      classNames={{
        root: "overlay modal-root",
        modalContainer: "member-container",
        modal: "member-modal",
        closeButton: "member-close",
      }}
      center
      closeIcon={<XMarkIcon className="h-6 w-6 text-cultiv-grey" />}
    >
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-full p-2 sm:p-8">
          <Loading />
        </div>
      ) : (
        <div className="gap-7 w-full h-full lg:px-14 lg:py-10 grid grid-cols-8">
          <div className="w-full flex col-span-3 item-center justify-center">
            <img
              src={memberData?.profile_picture}
              alt={memberData?.name}
              className="aspect-square object-contain"
            />
          </div>

          <div className="flex flex-col col-span-5 gap-1 justify-center px-2 md:px-0 sm:gap-2 2xl:gap-8">
            <h1 className="sm:text-lg md:text-2xl xl:text-4xl font-bold">{memberData?.name}</h1>
            <MarkDown
              articleBody={truncatedText}
              proseParagraph="prose-p:tracking-tighter prose-p:text-xs sm:prose-p:text-base md:prose-p:pb-2.5 lg:prose-p:leading-9 xl:prose-p:leading-9 xl:prose-p:text-2xl 3xl:prose-p:leading-10 3xl:prose-p:text-3xl"
            />
            {memberData?.link && (
              <WebsiteLinkButton link={memberData.link} text="Visit their website" />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}
