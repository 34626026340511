import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import CultivatorsImageModal from "../components/CultivatorsImageModal";
import GridController from "../components/GridController";
import Image from "../components/Image";
import Loading from "../components/Loading";
import MarkDown from "../components/MarkDown";
import PersonCard from "../components/PersonCard";
import useEvent from "../hooks/useEvent";
import usePathname from "../hooks/usePathname";
import { open } from "../redux/features/cultivatorsImageModalSlice";
import { Tab } from "../schema";

const Event = () => {
  const { eventID } = useParams();
  const { thirdDirectory } = usePathname();
  const [postData, isLoading] = useEvent(eventID as string);
  const dispatch = useDispatch();
  const newLocation = useLocation();
  const returnPage = newLocation.state?.from
    ? newLocation.state.from.pathname
      ? newLocation.state.from.pathname
      : newLocation.state.from
    : "";
  const openModal = (img: string) => {
    dispatch(open(img));
  };

  const eventTabs: Tab[] = [
    { name: "Overview", id: eventID || "" },
    { name: "Gallery", id: eventID + "/gallery" },
  ];
  const [displayed, setDisplayed] = useState<string>(
    thirdDirectory ? eventTabs[1].id : eventTabs[0].id
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const eventDate = new Date(postData?.post_meta?.start_date as string);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content={`${postData?.title} event post panel cultiv`} />
        <meta name="description" content={`This is an event about ${postData?.title}`} />
        <meta name="robots" content="index, follow" />
        <title>Cultiv | {`${postData ? postData.title : "Event"}`}</title>
      </Helmet>
      {isLoading ? (
        <div className="flex justify-center items-center h-0 min-h-screen">
          <Loading />
        </div>
      ) : (
        <div>
          {postData?.gallery && (
            <Image
              src={postData?.post_cover}
              height={500}
              className="object-cover w-full bg-black"
              alt={postData?.title}
            />
          )}
          <div
            className={`w-full flex flex-col justify-center items-center gap-14 py-6 lg:py-5 px-6 lg:px-20`}
          >
            {postData?.gallery && (
              <div className="w-full flex flex-col gap-4">
                <GridController
                  tabs={eventTabs}
                  displayed={displayed}
                  setDisplayed={setDisplayed}
                  page="culture/event"
                  returnPage={returnPage}
                />
              </div>
            )}
          </div>
          {displayed === postData?.title_slug + "/gallery" ? (
            <div className="w-full flex flex-col gap-4 px-6 lg:px-20">
              <div
                className={`grid md:grid-cols-3 grid-cols-2 w-full place-items-center gap-3 lg:gap-7.5`}
              >
                {postData?.gallery?.map((image, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        openModal(index.toString() || "");
                      }}
                      className="cursor-pointer w-full h-full"
                    >
                      <Image src={image} alt={`Gallery Image ${index}`} />
                    </button>
                  );
                })}
              </div>
              {postData.gallery && <CultivatorsImageModal gallery={postData.gallery} />}
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center gap-6 lg:gap-10 px-6 lg:px-20">
              <div
                className={`flex flex-col w-full justify-center ${
                  postData?.gallery ? "" : "items-center"
                }`}
              >
                <h1 className="text-xl lg:text-4xl font-bold">{postData?.title}</h1>
                <p className="text-xs lg:text-xl leading-5 lg:leading-7 text-gray-500 pt-1.5 lg:pt-3">
                  {eventDate && eventDate.toDateString()}, {postData?.post_meta?.location}
                </p>
                <p className="text-xs lg:text-xl leading-5 lg:leading-7 text-gray-500 pt-1.5 lg:pt-3">
                  {postData?.post_meta.author && `, By ${postData?.post_meta?.author}`}
                </p>
                <div
                  className={`w-full ${!postData?.gallery && "pb-8 border-b-2 border-gray-400"}`}
                ></div>
                {!postData?.gallery && (
                  <Image
                    src={postData?.post_cover}
                    width={1000}
                    height={500}
                    className=" object-cover pt-5"
                    alt={postData?.title}
                  />
                )}
                <div className="pt-3 lg:pt-5">
                  <MarkDown articleBody={postData?.md_text} proseParagraph="prose-2xl" />
                </div>
              </div>

              {postData?.panelists && (
                <div className="flex flex-col w-full gap-3 lg:gap-10">
                  <h1 className="lg:text-4xl text-xl font-bold">Meet The Panelists</h1>
                  <div
                    className={`grid lg:grid-cols-12 md:grid-cols-4 grid-cols-4 w-full place-items-center gap-3 lg:gap-x-7.5 lg:gap-y-6 items-start`}
                  >
                    {postData.panelists.map((panelist) => {
                      return <PersonCard key={panelist.id} person={panelist} panelist={true} />;
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Event;
