import usePodcastSeries from "../hooks/usePodcastSeries";
import { PodcastPlatform } from "../schema";
import Loading from "./Loading";
import PodcastSeriesCard from "./PodcastSeriesCard";
import SubscribeToPodcast from "./SubscribeToPodcast";

const platformsArr: PodcastPlatform[] = [
  { src: "/images/YouTube.png", url: "https://www.youtube.com/@cultivllc" },
  { src: "/images/Spotify.png", url: "https://spotify.com/" },
];

const PodcastSeriesGrid = () => {
  const [series, isLoading] = usePodcastSeries();

  return isLoading ? (
    <Loading />
  ) : (
    <div className="flex gap-10 flex-col">
      <SubscribeToPodcast platformsArr={platformsArr} />

      <div className="grid xl:grid-cols-12 md:grid-cols-2 grid-cols-4 gap-4 lg:gap-8 w-full place-items-start">
        {series.map((series) => {
          return <PodcastSeriesCard key={series.id} series={series} />;
        })}
      </div>
    </div>
  );
};

export default PodcastSeriesGrid;
