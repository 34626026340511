//type for nav drop down links

export type Navtab = {
  id: string;
  name: string;
  to: string;
  hasSublinks: boolean;
  sublinks?: SubLink[];
};

export type SubLink = {
  href: string;
  hash: string;
  label: string;
};

// Feed Item types

export type PostMeta = {
  author?: string;
  start_date?: string;
  end_date?: string;
  location?: string;
};
export type Tag = {
  id: number;
  tag: string;
};
export type Post = {
  id: number;
  service_id: number;
  title: string;
  title_slug: string;
  view_count: number;
  created_at: string;
  post_thumbnail: string;
  post_meta: PostMeta;
  rich_text?: string;
  plain_text?: string;
  md_text: string;
  post_cover?: string;
  post_summary?: string;
  tags: Tag[];
  gallery?: string[];
  panelists?: Person[];
};
export type FeedItemCard = {
  id: number;
  service_id: number;
  title: string;
  title_slug: string;
  post_summary: string;
  type: string;
  view_count: number;
  is_featured: number;
  created_at: string;
  post_thumbnail: string;
  post_meta: PostMeta;
  tags: Tag[];
};

// Company Card
export type Company = {
  id: number;
  name: string;
  name_slug: string;
  email: string;
  location: string;
  phone: string;
  description_summary: string;
  md_description: string;
  link: string;
  profile_picture: string;
};

// Podcast Card

export type PodcastSeries = {
  id: number;
  image: string;
  created_at: string;
  numberOfEpisodes: string;
  duration: string;
  title: string;
  description: string;
  audioSummary?: string;
  post_meta: PostMeta;
  tags: Tag[];
  slug: string;
  type: string;
  view_count: number;
  is_featured: number;
};

export type PodcastEpisode = {
  type: string;
  path: string;
  timestamp: number;
  size: number;
  dirname: string;
  basename: string;
  extension: string;
  filename: string;
  url: string;
};

export type PodcastEpisodeMeta = {
  title: string;
  link: string;
  pubDate: string;
  creator: string;
  categories: string[];
  guid: string;
  description: string;
  content: string;
  enclosure: {
    url: string;
    length: string;
    type: string;
  };
  subtitle: string;
  summary: string;
  author: string;
  explicit: string;
  duration: string;
};

export type PodcastPlatform = {
  src: string;
  url: string;
};

// Program Types
export type ProgramCard = {
  id: number;
  title: string;
  title_slug: string;
  program_summary: string;
  program_thumbnail: string;
  cycles_count: number;
};

export type Program = {
  program_cover: string;
  title: string;
  description: string;
  apply_link: string;
};

// Person
export type Contacts = {
  linkedin?: string;
  facebook?: string;
  twitter?: string;
  instagram?: string;
};

export type Organizations = {
  job_title?: string;
  organization_name?: string;
};
export type Person = {
  id: number;
  idea_name: string;
  profile_picture?: string;
  job_title?: string;
  name: string;
  bio?: string;
  md_bio?: string;
  organizations?: [Organizations];
  contacts?: Contacts;
};

export type Member = {
  logo: string;
  slug: string;
  name: string;
};

export type SliderItem = {
  post_cover: string;
  title: string;
  title_slug: string;
  type: string;
  isDirectory?: boolean;
};

export type FooterRedirectLink = {
  title: string;
  pathname: string;
};

export type CultivContact = {
  contact: string;
};

export type Tab = {
  name: string;
  id: string;
};

export type TestimonialItem = {
  fImgSrc: string;
  fTitle: string;
  fDescription: string;
  fName: string;
  sImgSrc: string;
  sTitle: string;
  sDescription: string;
  sName: string;
};

export enum Departments {
  principals = "principals",
  team = "team",
  cultivators = "cultivators",
  innovators = "innovators",
}

export enum CollectiveCompanies {
  members = "members",
  partners = "partners",
}

export enum Panels {
  panelI = 0,
  panelII = 1,
}

export enum contentTypes {
  "All" = "",
  "Articles" = "article",
  "News" = "news",
  "Events" = "event",
  "Podcasts" = "podcasts",
}
