import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Company } from "../schema";

const useCompanyProfile = (slug: string, isShort?: boolean): [Company, boolean] => {
  const [company, setCompany] = useState<Company>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    let cancelRequest = false;
    const fetchCompanyData = async () => {
      try {
        const response = await fetch(
          isShort
            ? import.meta.env.VITE_GET_COMPANY_PROFILE + slug + `?is_short=${isShort}`
            : import.meta.env.VITE_GET_COMPANY_PROFILE + slug
        );
        if (!response.ok)
          throw new Error(
            `error code: ${response.status}, The company you are looking for is not found or doesn't exist`
          );
        if (!cancelRequest) {
          const { data } = await response.json();
          setCompany(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        navigate(`/404`);
      }
    };
    fetchCompanyData();
    return () => {
      cancelRequest = true;
    };
  }, [slug]);
  return [company as Company, isLoading];
};

export default useCompanyProfile;
