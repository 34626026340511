import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FeedItemCard } from "../schema";

const useHomeFeed = (
  page?: number,
  type?: string,
  limit = "6"
): [FeedItemCard[], boolean, number] => {
  const [feedItems, setFeedItems] = useState<FeedItemCard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastPage, setLastPage] = useState<number>(0);
  const navigate = useNavigate();

  const fetchFeedItems = async (page?: number) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${import.meta.env.VITE_GET_FEED_ITEMS}?${
          type && `type=${type}`
        }&is_short=1&limit=${limit}&${page ? `page=${page}` : ""}`,
        { method: "GET" }
      );
      if (!response.ok) {
        throw new Error(`An error has occurred:  ${response.status} ${response.statusText}`);
      }
      const { data, meta } = await response.json();
      setLastPage(meta?.last_page || 0);
      return data;
    } catch (error) {
      console.error(error);
      navigate(`/404`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data: FeedItemCard[] = await fetchFeedItems(page);
      setFeedItems(data);
      setIsLoading(false);
    };
    fetchData();
  }, [page, type]);

  return [feedItems, isLoading, lastPage];
};

export default useHomeFeed;
